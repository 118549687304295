.LibraryCard {
    padding-bottom: var(--size-200);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: 8px;
    width: 100%;
    max-width: 272px;
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &:hover {
        border: 1px solid var(--brand-1);
    }
    .Thumbnail {
        width: 100%;
        height: 270px;
        overflow: hidden;
        position: relative;
        pointer-events: none;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: auto;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .ProjectTitle {
        p {
            width: 100%;
            line-height: 140%;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }
        button {
            width: 100%;
        }
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }
    .actionButtons {
        position: absolute;
        top: var(--size-100);
        right: var(--size-100);
        z-index: 1;
    }
}
