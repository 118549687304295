.HomePage {
    .flareGreen {
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: -200px;
            width: 300px;
            height: 600px;
            filter: blur(124px);
            background-color: var(--brand-2);
            opacity: 0.6;
            content: "";
            display: block;
            border-radius: 20%;
            transform: rotate(-45deg);
            z-index: -1;
        }
    }

    .section {
        &.Hero {
            &:after {
                position: absolute;
                left: 0;
                top: -200px;
                width: 300px;
                height: 600px;
                filter: blur(124px);
                background: var(--brand-2);
                opacity: 0.6;
                content: "";
                display: block;
                border-radius: 20%;
                transform: rotate(-45deg);
                z-index: -1;
            }
        }
    }
}
