.ComponentCardRow {
    border: 1px solid var(--border-neutral-subtle);
    border-radius: 8px;
    width: 100%;
    min-width: 100%;
    flex: 1;
    max-width: 100%;
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    &:hover {
        border: 1px solid var(--brand-2);
    }
    .AvatarGroup {
        position: absolute;
        right: var(--size-100);
        top: var(--size-100);
        &:after {
            content: "";
            display: block;
            width: 120%;
            height: 120%;
            top: -10%;
            right: -10%;
            background-color: var(--black);
            position: absolute;
            z-index: 0;
            filter: blur(32px);
        }
    }
    .CardFooter {
        justify-content: space-between;
        width: 100%;
        padding: 0 var(--size-200);
        margin-top: auto;
        z-index: 1;
    }
    .Thumbnail {
        width: 100%;
        height: 100%;
        max-width: 272px;
        overflow: hidden;
        position: relative;
        pointer-events: none;
        margin-left: var(--size-100);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: auto;
            transform: translateX(-50%) translateY(-50%);
        }
        .Tag {
            position: absolute;
            bottom: 8px;
            left: 8px;
            z-index: 1;
        }
    }
    p {
        width: 100%;
        line-height: 140%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
        & + p {
            margin-top: var(--size-100);
        }
        &.description {
            text-wrap: wrap;
            line-height: 140%;
            color: var(--foreground-neutral-subtle);
            margin-bottom: auto;
        }
    }
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    .actionButtons {
        z-index: 1;
    }
}
