.Widgets {
    max-width: 1200px;
    width: 100%;
    justify-content: center;
    --widgetSize: 280px;
    gap: var(--size-200);
    margin-top: var(--size-200);
    position: relative;
    z-index: 0;
    .Widget,
    .WidgetsPlaceholder {
        width: var(--widgetSize);
        background-color: var(--background-neutral-container);
        border-radius: 8px;
        padding: var(--size-300) var(--size-200) var(--size-150);
        flex-basis: var(--widgetSize);
        border: 1px dashed var(--border-neutral);
        button {
            align-self: center;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .WidgetsPlaceholder {
        flex: 1;
        min-height: 160px;
    }
}

.Updates {
    // max-width: calc(1200px - (var(--widgetSize) + var(--size-200)));
    align-self: center;
    width: 100%;
    overflow: hidden;
    flex: 2;
    .swiper {
        width: 100%;
    }
    .swiper-wrapper {
        // max-width: calc(100% - (var(--widgetSize) + var(--size-200)));
        width: 100%;
        align-items: stretch;
    }
    .swiper-slide {
        background-color: var(--background-neutral-inverted);
        padding: var(--size-300) var(--size-200) var(--size-150);
        border-radius: 8px;
        box-shadow: var(--shadow-level-1);
        // width: calc((100%) / 3) !important;
        height: auto;
        box-sizing: border-box;
        align-self: stretch;
        align-items: flex-start;
        > .flex {
            width: auto;
        }
        h4 {
            line-height: 1;
            color: var(--foreground-neutral-inverted);
        }
        p {
            color: var(--foreground-neutral-inverted);
        }
    }
    .CommitTitle {
        margin-bottom: var(--size-100);
        padding-right: 44px;
        word-wrap: break-word;
    }
    .CommitDate {
    }
    .UpdatesFooter {
        margin-bottom: 0;
        margin-top: auto;
        padding-top: var(--size-300);
    }
    .UpdatesLogo {
        width: 32px;
        height: auto;
        display: block;
        position: absolute;
        top: var(--size-100);
        right: var(--size-150);
    }
    button {
        margin-left: auto;
    }
}
