.templateCard {
    flex: 1;
    border: 1px solid var(--border0neutral-subtle);
    flex-basis: 284px;
    max-width: calc((100% - var(--size-600)) / 4);
    overflow: hidden;
    position: relative;
    z-index: 0;

    .templateImg {
        border-radius: var(--size-100);
        margin-bottom: var(--size-100);
        box-sizing: border-box;
        border: 1px solid var(--border-neutral);
        overflow: hidden;
        height: 0px;
        padding-bottom: 100%;
        position: relative;
        &:hover {
            border-color: var(--brand-1);
        }
        img {
            width: 120%;
            height: auto;
            display: block;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            top: 50%;
        }
    }
    &Featured {
        flex-basis: auto;
        max-width: calc(50% - var(--size-50));
        flex: 1;
        .templateImg {
            height: 200px;
            position: relative;
            padding-bottom: 0px;
            img {
                width: 100%;
                position: absolute;
                transform: translateX(-50%) translateY(-50%);
                left: 50%;
                top: 50%;
            }
        }
    }
    .button {
        position: absolute;
        top: var(--size-100);
        right: var(--size-100);
    }
}
