.draggableComponent {
    padding: var(--size-200);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: var(--size-50);
    background-color: var(--background-neutral-container);
    cursor: grab;
    user-select: none;
    width: 64px;
    height: 64px;
    color: var(--foreground-neutral);
    display: flex;
    align-items: center;
    justify-content: center;
    &[disabled] {
        background-color: var(--background-neutral-disabled);
        color: var(--foreground-neutral-disabled);
        pointer-events: none;
    }
    svg {
        width: 20px;
        height: 20px;
        display: block;
    }
}
.dropZoneContainer {
    width: 100%;
    position: relative;
    flex: 1;
    .dropZone {
        width: 100%;
    }
    .dndActions {
        position: absolute;
        right: var(--size-100);
        bottom: var(--size-100);
    }
}
.dnd {
    border: 1px solid var(--border-neutral);
    padding: var(--size-200);
    border-radius: var(--size-100);
    background-color: var(--background-neutral-container);
}
.Components {
    margin-bottom: var(--size-200);
}

.draggableComponent:active {
    background-color: var(--background-neutral-hover);
    cursor: grabbing;
}

.dropZone {
    padding: var(--size-300);
    border: 1px dashed var(--border-neutral-subtle);
    border-radius: var(--size-100);
    background-color: var(--background-neutral-container);
    text-align: center;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    gap: var(--size-100);
    flex-wrap: wrap;
}
.itemEdit {
    display: flex;
    flex-direction: row;
    gap: var(--size-150);
}
.labelEditSection {
    margin-top: var(--size-200);
    display: flex;
    flex-direction: column;
    gap: var(--size-150);
}
.ToastRoot {
    p {
        color: var(--foreground-neutral-inverted) !important;
    }
}
