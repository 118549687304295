.NavigationMenuRoot {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 8;
    align-items: center;
    width: 100vw;
    top: var(--size-200);
    padding: 0 var(--size-200);
    box-sizing: border-box;
    &.scrolled {
        .NavigationMenuList {
            background-color: var(--background-neutral-container);
            padding: var(--size-50) var(--size-100) var(--size-50) var(--size-200);
            max-width: 900px;
            margin: auto;
            transition: 250ms ease 0s;
            box-shadow: var(--shadow-level-1);
        }
    }
    .Logo {
        max-width: 87px;
        height: auto;
        display: block;
        width: 100%;
    }
    & > div {
        width: 100%;
        max-width: 1200px;
        align-self: center;
    }
    .SwitchRoot + .Label {
        height: 15px;
        margin-right: var(--size-200);
    }
}

.NavigationMenuList {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    max-width: 1200px;
    width: 100%;
    flex: 1;
    transition: 250ms ease 0s;
    border-radius: var(--size-50);
    padding: 0;

    .CTA {
        margin-left: auto;
    }
}

.NavigationMenuTrigger,
.NavigationMenuLink {
    padding: 0 var(--size-150);
    outline: none;
    user-select: none;
    font-weight: bold;
    line-height: 1;
    border-radius: 4px;
    font-size: 14px;
    color: var(--foreground-neutral);
    height: 40px;
    line-height: 40px;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-bottom: 0px !important;
    box-sizing: border-box;
}
.NavigationMenuTrigger:focus,
.NavigationMenuLink:focus {
    box-shadow: var(--focus);
}
.NavigationMenuTrigger:hover,
.NavigationMenuLink:hover {
    background-color: var(--background-neutral-hover);
}

.NavigationMenuTrigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-50);
}

.NavigationMenuLink {
    display: block;
    text-decoration: none;
    position: relative;
}

.NavigationMenuContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation-duration: 250ms;
    animation-timing-function: ease;
}
.NavigationMenuContent[data-motion="from-start"] {
    animation-name: enterFromLeft;
}
.NavigationMenuContent[data-motion="from-end"] {
    animation-name: enterFromRight;
}
.NavigationMenuContent[data-motion="to-start"] {
    animation-name: exitToLeft;
}
.NavigationMenuContent[data-motion="to-end"] {
    animation-name: exitToRight;
}

.MobileNavigation {
    position: fixed;
    width: auto;
    right: var(--size-200);
    left: var(--size-200);
    height: calc(100vh - var(--size-1000));
    background-color: var(--background-neutral-container);
    top: 72px;
    padding: 0 var(--size-100);
    border-radius: var(--size-50);
    box-shadow: var(--shadow-level-1);
    .NavigationMenuLink {
        width: min-content;
    }
    .List.two {
        width: auto;
        padding: 0;
    }
    .NavLabel {
        padding: 0 var(--size-150);
    }
    .ScrollAreaViewport > div {
        flex-direction: column;
        min-height: auto;
        gap: var(--size-200);
        padding: 0 var(--size-100);
    }
    .ScrollAreaViewport {
        padding: var(--size-200) 0;
    }
    p {
        text-align: left !important;
    }
}
.MobileMenuToggle,
.MobileNavigation {
    display: none !important;
}
@media screen and (max-width: 835px) {
    .MainNavigation,
    .CTA {
        display: none !important;
    }
    .MobileMenuToggle,
    .MobileNavigation {
        display: flex !important;
    }
}

@media only screen and (min-width: 600px) {
    .NavigationMenuContent {
        width: auto;
    }
}

.NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
    transition:
        width,
        transform 250ms ease;
}

.NavigationMenuIndicator[data-state="visible"] {
    animation: fadeIn 200ms ease;
}
.NavigationMenuIndicator[data-state="hidden"] {
    animation: fadeOut 200ms ease;
}

.NavigationMenuViewport {
    position: relative;
    transform-origin: top center;
    margin-top: 10px;
    width: 100%;
    background-color: var(--background-neutral-container);
    border-radius: var(--size-100);
    border: 1px solid var(--border-neutral-subtle);
    overflow: hidden;
    box-shadow: var(--shadow-level-2);
    height: var(--radix-navigation-menu-viewport-height);
    transition:
        width,
        height,
        250ms ease;
}
.NavigationMenuViewport[data-state="open"] {
    animation: scaleIn 250ms ease;
}
.NavigationMenuViewport[data-state="closed"] {
    animation: scaleOut 250ms ease;
}
@media only screen and (min-width: 600px) {
    .NavigationMenuViewport {
        width: var(--radix-navigation-menu-viewport-width);
    }
}

.List {
    display: grid;
    padding: var(--size-200);
    margin: 0;
    column-gap: var(--size-150);
    list-style: none;
}
@media only screen and (min-width: 600px) {
    .List.one {
        width: 500px;
        grid-template-columns: 0.75fr 1fr;
    }
    .List.two {
        width: 600px;
        grid-auto-flow: column;
        grid-template-rows: repeat(3, 1fr);
    }
}

.ListItemLink {
    display: block;
    outline: none;
    text-decoration: none;
    user-select: none;
    padding: var(--size-150);
    border-radius: var(--size-50);
    font-size: 14px;
    line-height: 1;
}
.ListItemLink:focus {
    box-shadow: var(--focus);
}
.ListItemLink:hover {
    background-color: var(--brand-2);
    color: var(--black);
    .ListItemHeading,
    .ListItemText,
    p {
        color: var(--black);
    }
}

.ListItemHeading {
    font-weight: bold;
    line-height: 110%;
    margin-bottom: var(--size-100);
    color: var(--foreground-neutral);
}

.ListItemText {
    color: var(--foreground-neutral);
    line-height: 140%;
    font-weight: initial;
    font-size: 14px;
}

.Callout {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: red;
    border-radius: var(--size-50);
    padding: var(--size-200);
    text-decoration: none;
    outline: none;
    user-select: none;
}
.Callout:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
}

.CalloutHeading {
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 16px;
    margin-bottom: 7px;
}

.CalloutText {
    color: var(--mauve-4);
    font-size: 14px;
    line-height: 1.3;
}

.ViewportPosition {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    left: 0;
    perspective: 2000px;
}

.CaretDown {
    position: relative;
    color: var(--foreground-neutral);
    top: 1px;
    transition: transform 250ms ease;
}
[data-state="open"] > .CaretDown {
    transform: rotate(-180deg);
}

.Arrow {
    position: relative;
    top: 70%;
    background-color: var(--background-neutral-container);
    border: 1px solid var(--border-neutral-subtle);
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
}

@keyframes enterFromRight {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enterFromLeft {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes exitToRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(200px);
    }
}

@keyframes exitToLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-200px);
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: rotateX(-30deg) scale(0.9);
    }
    to {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }
}

@keyframes scaleOut {
    from {
        opacity: 1;
        transform: rotateX(0deg) scale(1);
    }
    to {
        opacity: 0;
        transform: rotateX(-10deg) scale(0.95);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
