.FigmaFileDetails {
    // padding: var(--size-300) var(--size-200);
    // border-radius: var(--size-100);
    // border: 1px solid var(--border-neutral-subtle);
    // position: relative;
    .logo {
        max-width: 42px;
    }
    svg {
        color: var(--foreground-neutral);
    }
    .noComments {
        width: 100%;
        flex: 1;
        height: 100%;
    }
    .actionButtons {
        position: absolute;
        top: var(--size-100);
        right: var(--size-100);
        z-index: 1;
    }
    .commentCard {
        border: 1px solid var(--border-neutral-subtle);
        border-radius: var(--size-100);
        padding: var(--size-200);
        min-width: 300px;
        &:first-child {
            margin-left: var(--size-200);
        }
        &:last-child {
            margin-right: var(--size-200);
        }
    }
    .cubeData {
        width: 12px;
        height: 12px;
        margin: 2px 4px;
        border-radius: 3px;
        opacity: 0.6;
        &[style*="var(--brand-2)"] {
            opacity: 1;
        }
        &.cubeCurrentMonth {
            width: 16px;
            height: 16px;
            margin: 0 2px;
            border-radius: 4px;
            opacity: 1;
        }
    }
    .legend {
        min-width: 96px;
        margin-bottom: var(--size-100);
        p {
            line-height: 16px;
        }
    }
    .activityRow {
        display: flex;
        flex-direction: row;
        gap: 0;
        &.Header {
            padding-left: calc(96px + var(--size-200));
            &.Year {
                gap: var(--size-150);
                margin-bottom: var(--size-200);
                h4 {
                    line-height: var(--size-400);
                    padding-bottom: 2px;
                }
            }

            .IconButtonContainer {
                width: 40px;
                height: 40px;
            }
        }
        .activityMonth {
            display: flex;
            flex-direction: row;
            gap: 0;
        }
    }
    .activityGraph {
        overflow: hidden;
        // margin-bottom: var(--size-600);
        margin-top: var(--size-300);
        z-index: 0;
    }
    .extraWidgets:not(:empty) {
        margin-top: var(--size-400);
    }
    .figmaStats {
        margin-top: var(--size-300);
    }
    .widgetCard {
        border: 1px solid var(--border-neutral-subtle);
        border-radius: var(--size-100);
        padding: var(--size-100) var(--size-200) var(--size-200) var(--size-200);
        box-sizing: border-box;
        h4 {
            height: 40px;
            line-height: 36px;
        }
        .SelectTrigger {
            flex: 1;
        }
    }
    .yourComponents {
        max-width: 50%;
    }
    .yourComments {
        overflow: hidden;
        width: 100%;
        flex: 1;
        padding: var(--size-100) 0 var(--size-200);
        .Header {
            padding: 0 var(--size-200);
        }
    }

    .ScrollAreaRoot {
        padding-bottom: var(--size-100);
        height: auto;
        .ScrollAreaViewport {
            width: auto;
        }
        .ScrollAreaViewport > div {
            min-height: 0;
        }
    }
}
.DropdownMenuContent.Filter {
    max-width: 300px;
}
.CommentReply {
    padding: 0;
    .textareafield {
        textarea {
            border: 0;
            min-height: 164px;
        }
    }
}
