.Table {
    border-collapse: collapse;
    width: 100%;
    font-family: inherit;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    // margin: var(--size-400) 0;
    td,
    th {
        padding: 16px;
        text-align: left;
        font-size: 16px;
        background-color: var(--background-neutral-container);
        color: var(--foreground-neutral);
        border-color: var(--border-neutral-subtle);
        border-style: solid;
        border-width: 0;
        border-bottom-width: 1px;
        position: relative;
        border-right-width: 1px;
        vertical-align: text-top;
    }
    & tr:nth-child(2) td:first-child {
        border-top-left-radius: var(--size-100);
    }
    & tr:nth-child(2) td:last-child {
        border-top-right-radius: var(--size-100);
    }
    & tr:first-child th:first-child {
        border-top-left-radius: var(--size-100);
    }
    & tr:first-child th:last-child {
        border-top-right-radius: var(--size-100);
    }
    & tr:last-child td:first-child {
        border-bottom-left-radius: var(--size-100);
    }
    & tr:last-child td:last-child {
        border-bottom-right-radius: var(--size-100);
    }
    & tr:nth-child(2) td {
        border-top-width: 1px;
    }
    & tr:first-child th {
        border-bottom: 0;
    }
    & tr:first-child td:first-child {
        border-left-width: 1px;
    }
    & tr:first-child th {
        border-right-width: 0px;
    }
    & tr:first-child td {
        border-top-width: 1px;
    }
    th {
        background-color: transparent;
    }
    & tr:not(:first-child) td:first-child,
    & tr:not(:first-child) th:first-child {
        border-left-width: 1px;
    }
    th {
        p {
            font-weight: bold;
        }
    }

    &.colorTable {
        tr td:first-child {
            height: 64px;
            width: 200px;
            .customCell p {
                background-color: var(--background-neutral-container);
                padding: 0 var(--size-100);
                border-radius: 2px;
                font-size: 12px;
                width: 64px;
                position: absolute;
                top: var(--size-50);
                left: var(--size-50);
            }
        }
        .copyButton {
            background-color: var(--background-neutral-container);
            border-radius: var(--size-50);
            margin-left: auto;
            position: absolute;
            top: var(--size-50);
            right: var(--size-50);
        }
    }
}
