.NotesContainer {
    z-index: 0;
    position: relative;
    .gjs-one-bg {
        background-color: transparent;
    }
    & > h4 {
        margin-bottom: var(--size-200);
    }
    .detector {
        &.blocksHeight {
            height: var(--size-1000);
        }
    }
    .MyBlocks#blocks {
        margin: var(--size-200) 0;
        align-items: center;
        justify-content: flex-start;
        max-width: 900px;
        align-self: center;
        width: 100%;
        position: relative;
        z-index: 8;

        .gjs-blocks-cs {
            width: 100%;
            .gjs-blocks-no-cat {
                display: flex;
                flex-direction: row;
                width: 100%;
                .gjs-blocks-c {
                    display: flex;
                    flex-direction: row;
                    gap: var(--size-100);
                    width: 100%;
                }
            }
        }
        .gjs-block-categories {
            width: fit-content;
        }
        &.FloatingBlocks {
            left: 0;
            top: 0;
            bottom: 0;
            width: auto;
            .gjs-blocks-cs {
                flex-direction: column;

                .gjs-blocks-no-cat {
                    flex-direction: column;
                    width: auto;
                    .gjs-blocks-c {
                        flex-direction: column;
                        width: auto;
                        .gjs-block {
                            opacity: 0;
                            animation: showBlocks 250ms ease forwards var(--animation-delay, 0s);
                            @keyframes showBlocks {
                                0% {
                                    opacity: 0;
                                    transform: translateX(-8px);
                                }
                                100% {
                                    opacity: 1;
                                    transform: translateX(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .gjs-editor-cont {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }
    .gjs-block {
        min-width: 64px;
        min-height: 64px;
        max-width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: var(--background-neutral-page-front);
        box-shadow: none;
        margin: 0;
        // border: 1px dashed var(--black-200);
        border: none;
        position: relative;
        &:after {
            position: absolute;
            z-index: -1;
            content: "";
            display: block;
            height: 68px;
            width: 68px;
            background-repeat: no-repeat;
            background-size: 68px 68px;
            background-image: url('data:image/svg+xml,<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M65.4815 0H0L1.57407 68H68L65.4815 0Z" fill="%23EBEAEC"/></svg>');
        }
        &:before {
            position: absolute;
            z-index: -2;
            content: "";
            display: block;
            height: 56px;
            width: 56px;
            background-repeat: no-repeat;
            background-size: 48px 48px;
            transform: rotate(-7deg);
            bottom: -8px;
            left: 10px;
            filter: blur(4px);
            opacity: 0.7;
            background-image: url('data:image/svg+xml,<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M65.4815 0H0L1.57407 68H68L65.4815 0Z" fill="%23000000"/></svg>');
        }
        &:hover {
            // box-shadow: var(--shadow-level-2);
            // background-color: var(--brand-2);
            .gjs-block-label {
                color: var(--black);
            }
            &:after {
                background-image: url('data:image/svg+xml,<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M65.4815 0H0L1.57407 68H68L65.4815 0Z" fill="%2307feb4"/></svg>');
            }
        }
        .gjs-block-label {
            // font-family: "MuseoModerno";
            // font-weight: 600;
            font-family: var(--font-accent);
            font-weight: bold;
            // color: var(--foreground-neutral);
            color: var(--black);
            font-size: 16px;
            line-height: 20px;
            align-items: center;
            justify-content: center;
            display: flex;
            gap: 2px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .gjs-editor {
        width: calc(100% + var(--size-600));
        background-color: transparent;
        padding: 0 var(--size-300);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        margin: 0 -24px;
    }
    .gjs-cv-canvas {
        position: relative;
        width: 100%;
        border-radius: 0px;
        color: var(--white);
        overflow: visible;
        box-sizing: border-box;
        max-width: 900px;
        top: 0;
        bottom: var(--size-200);
        height: 100%;
        .gjs-cv-canvas__frames {
            inset: 0;
            // position: relative;
            height: 100%;
            width: 100%;
            top: var(--size-300);
            bottom: var(--size-200);
        }

        .gjs-frame {
            height: 100%;
        }
    }
    .gjs-toolbar {
        background-color: transparent;
    }

    .gjs-rte-actionbar {
        background-color: transparent;
    }
    .gjs-rte-toolbar-ui {
        border: none;
    }
    .gjs-toolbar-items,
    .gjs-rte-actionbar {
        display: flex;
        flex-direction: row;
        gap: var(--size-50);
        transform: translateY(-6px);
        background-color: var(--background-neutral-container);
        box-shadow: var(--shadow-level-2);
        padding: var(--size-50);
        border-radius: 4px;
        border: 1px solid var(--border-neutral-subtle);
        box-sizing: border-box;
        .gjs-toolbar-item,
        .gjs-rte-action {
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
            width: 32px;
            height: 32px;
            padding: 0px;
            cursor: pointer;
            display: flex;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            color: var(--foreground-neutral-subtle);
            outline: none;
            box-shadow: none;
            border: none;
            &:hover {
                // background-color: var(--blue-2-3);
                color: var(--black);
                background-color: var(--background-primary);
            }
            svg {
                width: 20px;
                height: 20px;
                box-shadow: var(--shadow-level-2);
            }
        }
        .gjs-toolbar-item:first-child,
        .gjs-toolbar-item.gjs-no-touch-actions,
        .gjs-toolbar-item:nth-of-type(3),
        .gjs-toolbar-item:nth-of-type(4) {
            background-color: var(--foreground-neutral-subtle);
            mask-size: 20px 20px;
            mask-repeat: no-repeat;
            mask-position: center;
            &:hover {
                mask-image: none;
                background-color: var(--background-primary);
            }
        }
        .gjs-toolbar-item:first-child {
            mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.14645 2.14645C7.34171 1.95118 7.65829 1.95118 7.85355 2.14645L11.8536 6.14645C12.0488 6.34171 12.0488 6.65829 11.8536 6.85355C11.6583 7.04882 11.3417 7.04882 11.1464 6.85355L8 3.70711L8 12.5C8 12.7761 7.77614 13 7.5 13C7.22386 13 7 12.7761 7 12.5L7 3.70711L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355C2.95118 6.65829 2.95118 6.34171 3.14645 6.14645L7.14645 2.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            svg {
                display: none;
            }
            &:hover {
                background-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.14645 2.14645C7.34171 1.95118 7.65829 1.95118 7.85355 2.14645L11.8536 6.14645C12.0488 6.34171 12.0488 6.65829 11.8536 6.85355C11.6583 7.04882 11.3417 7.04882 11.1464 6.85355L8 3.70711L8 12.5C8 12.7761 7.77614 13 7.5 13C7.22386 13 7 12.7761 7 12.5L7 3.70711L3.85355 6.85355C3.65829 7.04882 3.34171 7.04882 3.14645 6.85355C2.95118 6.65829 2.95118 6.34171 3.14645 6.14645L7.14645 2.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            }
        }
        .gjs-toolbar-item.gjs-no-touch-actions {
            mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.81819 0.93179C7.64245 0.756054 7.35753 0.756054 7.18179 0.93179L5.43179 2.68179C5.25605 2.85753 5.25605 3.14245 5.43179 3.31819C5.60753 3.49392 5.89245 3.49392 6.06819 3.31819L6.99999 2.38638V5.49999C6.99999 5.77613 7.22385 5.99999 7.49999 5.99999C7.77613 5.99999 7.99999 5.77613 7.99999 5.49999V2.38638L8.93179 3.31819C9.10753 3.49392 9.39245 3.49392 9.56819 3.31819C9.74392 3.14245 9.74392 2.85753 9.56819 2.68179L7.81819 0.93179ZM7.99999 9.49999C7.99999 9.22385 7.77613 8.99999 7.49999 8.99999C7.22385 8.99999 6.99999 9.22385 6.99999 9.49999V12.6136L6.06819 11.6818C5.89245 11.5061 5.60753 11.5061 5.43179 11.6818C5.25605 11.8575 5.25605 12.1424 5.43179 12.3182L7.18179 14.0682C7.35753 14.2439 7.64245 14.2439 7.81819 14.0682L9.56819 12.3182C9.74392 12.1424 9.74392 11.8575 9.56819 11.6818C9.39245 11.5061 9.10753 11.5061 8.93179 11.6818L7.99999 12.6136V9.49999ZM8.99999 7.49999C8.99999 7.22385 9.22385 6.99999 9.49999 6.99999H12.6136L11.6818 6.06819C11.5061 5.89245 11.5061 5.60753 11.6818 5.43179C11.8575 5.25605 12.1424 5.25605 12.3182 5.43179L14.0682 7.18179C14.2439 7.35753 14.2439 7.64245 14.0682 7.81819L12.3182 9.56819C12.1424 9.74392 11.8575 9.74392 11.6818 9.56819C11.5061 9.39245 11.5061 9.10753 11.6818 8.93179L12.6136 7.99999H9.49999C9.22385 7.99999 8.99999 7.77613 8.99999 7.49999ZM3.31819 6.06819L2.38638 6.99999H5.49999C5.77613 6.99999 5.99999 7.22385 5.99999 7.49999C5.99999 7.77613 5.77613 7.99999 5.49999 7.99999H2.38638L3.31819 8.93179C3.49392 9.10753 3.49392 9.39245 3.31819 9.56819C3.14245 9.74392 2.85753 9.74392 2.68179 9.56819L0.93179 7.81819C0.756054 7.64245 0.756054 7.35753 0.93179 7.18179L2.68179 5.43179C2.85753 5.25605 3.14245 5.25605 3.31819 5.43179C3.49392 5.60753 3.49392 5.89245 3.31819 6.06819Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            svg {
                display: none;
            }
            &:hover {
                background-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.81819 0.93179C7.64245 0.756054 7.35753 0.756054 7.18179 0.93179L5.43179 2.68179C5.25605 2.85753 5.25605 3.14245 5.43179 3.31819C5.60753 3.49392 5.89245 3.49392 6.06819 3.31819L6.99999 2.38638V5.49999C6.99999 5.77613 7.22385 5.99999 7.49999 5.99999C7.77613 5.99999 7.99999 5.77613 7.99999 5.49999V2.38638L8.93179 3.31819C9.10753 3.49392 9.39245 3.49392 9.56819 3.31819C9.74392 3.14245 9.74392 2.85753 9.56819 2.68179L7.81819 0.93179ZM7.99999 9.49999C7.99999 9.22385 7.77613 8.99999 7.49999 8.99999C7.22385 8.99999 6.99999 9.22385 6.99999 9.49999V12.6136L6.06819 11.6818C5.89245 11.5061 5.60753 11.5061 5.43179 11.6818C5.25605 11.8575 5.25605 12.1424 5.43179 12.3182L7.18179 14.0682C7.35753 14.2439 7.64245 14.2439 7.81819 14.0682L9.56819 12.3182C9.74392 12.1424 9.74392 11.8575 9.56819 11.6818C9.39245 11.5061 9.10753 11.5061 8.93179 11.6818L7.99999 12.6136V9.49999ZM8.99999 7.49999C8.99999 7.22385 9.22385 6.99999 9.49999 6.99999H12.6136L11.6818 6.06819C11.5061 5.89245 11.5061 5.60753 11.6818 5.43179C11.8575 5.25605 12.1424 5.25605 12.3182 5.43179L14.0682 7.18179C14.2439 7.35753 14.2439 7.64245 14.0682 7.81819L12.3182 9.56819C12.1424 9.74392 11.8575 9.74392 11.6818 9.56819C11.5061 9.39245 11.5061 9.10753 11.6818 8.93179L12.6136 7.99999H9.49999C9.22385 7.99999 8.99999 7.77613 8.99999 7.49999ZM3.31819 6.06819L2.38638 6.99999H5.49999C5.77613 6.99999 5.99999 7.22385 5.99999 7.49999C5.99999 7.77613 5.77613 7.99999 5.49999 7.99999H2.38638L3.31819 8.93179C3.49392 9.10753 3.49392 9.39245 3.31819 9.56819C3.14245 9.74392 2.85753 9.74392 2.68179 9.56819L0.93179 7.81819C0.756054 7.64245 0.756054 7.35753 0.93179 7.18179L2.68179 5.43179C2.85753 5.25605 3.14245 5.25605 3.31819 5.43179C3.49392 5.60753 3.49392 5.89245 3.31819 6.06819Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            }
        }
        .gjs-toolbar-item:nth-of-type(3) {
            svg {
                display: none;
            }

            mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            &:hover {
                background-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            }
        }
        .gjs-toolbar-item:nth-of-type(4) {
            svg {
                display: none;
            }
            mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H5H10H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H11V12C11 12.5523 10.5523 13 10 13H5C4.44772 13 4 12.5523 4 12V4L3.5 4C3.22386 4 3 3.77614 3 3.5ZM5 4H10V12H5V4Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            &:hover {
                background-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2H9.5C9.77614 2 10 1.77614 10 1.5C10 1.22386 9.77614 1 9.5 1H5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3H5H10H11.5C11.7761 3 12 3.22386 12 3.5C12 3.77614 11.7761 4 11.5 4H11V12C11 12.5523 10.5523 13 10 13H5C4.44772 13 4 12.5523 4 12V4L3.5 4C3.22386 4 3 3.77614 3 3.5ZM5 4H10V12H5V4Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
            }
        }
    }
}
.gjs-mdl-container {
    background-color: var(--black-alpha);
}
.gjs-mdl-dialog {
    background-color: var(--background-neutral-container) !important;
    padding: var(--size-700) var(--size-600) var(--size-700) var(--size-600);
    border-radius: 6px;
    box-shadow: var(--shadow-level-2);
    color: var(--foreground-neutral);
    .gjs-btn-prim {
        padding: 0 var(--size-200);
        font-family: var(--font-accent);
        font-weight: bold;
        text-decoration: none;
        font-size: 14px;
        height: 44px;
        min-width: 44px;
        line-height: 20px;
        gap: var(--size-50);
        border-radius: 4px;
        color: var(--foreground-primary);
        background-color: var(--background-primary);
        &:hover {
            background-color: var(--background-primary-hover);
        }
        &:active {
            background-color: var(--background-primary-active);
        }
    }
    input::placeholder {
        color: var(--foreground-neutral-placeholder) !important;
    }

    .gjs-am-add-asset {
        display: flex;
        flex-direction: row;
        .gjs-am-add-field {
            float: none;
            flex: 1;
            display: flex;
            width: auto;
            margin-right: var(--size-100);
            input {
                min-width: 164px;
            }
        }
    }
    .gjs-asset-manager {
        display: flex;
        gap: var(--size-200);
        .gjs-btn-prim {
            text-align: center;
            width: fit-content;
        }
        .gjs-am-file-uploader {
            float: none;
            width: auto;
            flex: 1;
        }
        .gjs-am-assets-cont {
            float: none;
            width: auto;
            flex: 1;
            height: 100%;
            padding: 0;
            .gjs-am-assets-header {
                padding: 0;
            }
        }
    }
}
.gjs-am-assets-cont,
.gjs-am-file-uploader > form {
    background-color: transparent;
    margin: 0;
}
.gjs-am-file-uploader > form {
    border: 1px dashed var(--border-neutral-subtle);
    background-color: var(--background-neutral);
}
#gjs-am-title {
    font-size: 16px;
    color: var(--foreground-neutral);
    line-height: 160%;
    width: auto;
    margin: 0;
    padding: 0;
    font-family: var(--font-body);
    font-weight: normal;
}
.gjs-mdl-title {
    font-family: var(--font-accent);
    font-weight: bold;
    color: var(--foreground-neutral);
    font-size: var(--size-300);
    margin-bottom: var(--size-200);
}

.gjs-mdl-content {
    padding: 0;
}
.gjs-mdl-header {
    padding: 0;
    position: unset;
    border: none;
}
.gjs-frame-wrapper {
    background-color: transparent;
}
.gjs-am-asset {
    border: 1px solid var(--border-neutral-subtle);
    padding: var(--size-100);
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    flex: 1;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: var(--size-100);
    gap: var(--size-200);
    .gjs-am-preview-cont {
        border-radius: 0;
        background-color: transparent;
        height: 72px;
        width: 72px;
        border: 1px solid var(--border-neutral-subtle);
        border-radius: 4px;
        box-sizing: border-box;
    }
    .gjs-am-preview {
        background-color: transparent;
    }
    .gjs-am-meta {
        width: 70%;
        float: none;
        font-size: 14px;
        padding: 0;
        padding-right: var(--size-400);

        line-height: 140%;
        box-sizing: border-box;
        color: var(--foreground-neutral);
        font-family: var(--font-body);
        font-weight: normal;
        margin-top: var(--size-50);
        .gjs-am-name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
        }
    }
    .gjs-am-dimensions {
        color: var(--foreground-neutral-subtle);
        opacity: 1;
        font-size: 14px;
        font-family: var(--font-body);
        font-weight: normal;
    }
    .gjs-am-meta > div {
        margin-bottom: var(--size-100);
    }
}

.gjs-mdl-btn-close,
.gjs-am-close {
    text-indent: -99999px;
    opacity: 1;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    z-index: 7;
    top: var(--size-100);
    right: var(--size-100);

    &:after {
        position: absolute;
        inset: 0;
        z-index: 0;
        content: "";
        display: block;
        background-color: var(--foreground-neutral);
        position: absolute;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 20px 20px;
        mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
    }
    &:hover {
        background-color: var(--background-neutral-hover);
        opacity: 1;
    }
    &:active {
        background-color: var(--background-neutral-active);
    }
}

.gjs-am-close {
    width: 36px;
    height: 36px;
    top: var(--size-50);
    right: var(--size-50);
}

.componentInfo {
    background-color: var(--background-neutral-container);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: var(--size-50);
    box-shadow: var(--shadow-level-1);
    position: fixed;
    bottom: 16px;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    padding: var(--size-150) var(--size-200);
    color: var(--foreground-neutral);
    align-items: center;
    gap: var(--size-100);
    display: none;
    &.showToolbar {
        display: flex;
    }
    input {
        border: 1px solid var(--border-neutral);
        height: 40px;
        border-radius: 4px;
        padding: 0 var(--size-100);
    }
}
