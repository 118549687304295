.GithubIntegrationMessage {
    padding: var(--size-100);
    flex: 1;
    // width: calc(100% - var(--size-400));
    width: 100%;
    margin: var(--size-100);
    align-self: center;
    box-sizing: border-box;
    // max-height: 50px;
    height: max-content;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: var(--size-150);
    background-color: var(--background-neutral-container-inverted);
    p {
        color: var(--foreground-neutral-inverted);
        // text-align: center;
        width: auto;
    }
    img {
        width: 24px;
        height: auto;
        display: block;
    }
}

.GithubBranchCommit {
    padding: var(--size-200) var(--size-500) var(--size-200) var(--size-200);
    flex: 1;
    width: 100%;
    margin: var(--size-100);
    align-self: center;
    box-sizing: border-box;
    max-height: 100px;
    height: max-content;
    align-items: flex-start;
    border-radius: 4px;
    justify-content: flex-start;
    position: relative;
    background-color: var(--background-neutral-page-back);
    p {
        color: var(--foreground-neutral);
        text-wrap: wrap;
        line-height: 140%;
    }
    svg {
        * {
            fill: var(--foreground-neutral-subtle);
        }
        width: 100%;
        max-width: 24px;
        height: auto;
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
