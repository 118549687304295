.PagePreview {
    padding: var(--size-1600) 0;
    width: 100%;
    background-color: var(--background-neutral-page-back);
    div[role="menubar"] {
        width: 100%;
        max-width: 600px;
        align-self: center;
    }

    .PagePreviewBodyContainer {
        max-width: 1440px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;

        .sidebarMenu {
            width: 100%;
            max-width: 248px;
            box-sizing: border-box;
            padding: var(--size-300) var(--size-150);
            border-radius: var(--size-100);
            border: 1px solid var(--border-neutral-subtle);
            background-color: var(--background-neutral-container);
            gap: var(--size-50);
            max-height: 70vh;
            z-index: 3;
            position: relative;
            .inputfield {
                min-width: auto;
                width: 100%;
                margin-bottom: var(--size-150);
            }
            &.Touched {
                position: fixed;
                top: 80px;
                left: 16px;
            }
            h4 {
                margin-bottom: var(--size-150);
                padding-bottom: var(--size-50);
                border-bottom: 1px solid var(--border-neutral-subtle);
            }
            button {
                text-align: left;
                justify-content: flex-start;

                span {
                    width: 100%;
                    overflow: hidden;
                    word-wrap: none;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                }
                svg {
                    min-width: 20px;
                }
            }
            .ScrollAreaViewport > div {
                display: flex !important;
                flex-direction: column;
                gap: var(--size-50);
                width: 100%;
                min-height: auto;
            }
            .ScrollAreaViewport {
                overflow-x: visible;
            }
        }
        .PagePreviewBody {
            max-width: 1000px;
            width: 100%;
            position: relative;
            z-index: 0;
            box-sizing: border-box;
            .pageContent {
                background-color: var(--background-neutral-container);
                width: 100%;
                padding: var(--size-400) var(--size-300) var(--size-500);
                border-radius: 8px;
                height: auto;
                box-shadow: var(--shadow-level-1);
                &:not(:last-child) {
                    margin-bottom: var(--size-200);
                }
            }
            &.Touched {
                max-width: calc(1000px + (248px + 32px));
                padding-left: calc(248px + 32px);
            }
            // background-color: var(--background-neutral-container);
            @import "./ComponentRelations.scss";

            .FigmaIntegrationMessage {
                margin: 0;
                p {
                    color: var(--foreground-neutral-inverted);
                }
                img {
                    max-width: 40px;
                    margin-top: 0;
                }
            }

            .Loader {
                &:after {
                    display: none;
                }
            }
        }
    }
    .PagePreviewHeader {
        max-width: 1000px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: var(--size-200);
        z-index: 2;
    }
}

.IconBox {
    min-width: 136px;
    flex: 1;
    height: 136px;
    border: 1px solid var(--border-neutral-subtle);
    border-radius: var(--size-100);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    background-color: var(--background-neutral-container);
    overflow: hidden;
    padding: var(--size-300) 0;
    // color: var(--foreground-neutral);
    button {
        position: absolute;
        bottom: var(--size-50);
        right: var(--size-50);
    }
    span {
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: 10px;
        color: var(--foreground-neutral);
    }

    // svg {
    //     * {
    //         fill: currentColor;
    //     }
    // }
}
