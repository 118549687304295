.AlertDialogRoot {
}

.AlertDialogOverlay {
    background-color: var(--black-alpha);
    position: fixed;

    z-index: 11;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AlertDialogContent {
    background-color: var(--background-neutral-container);
    border-radius: var(--size-100);
    box-shadow: var(--shadow-level-2);
    position: fixed;
    z-index: 12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 552px;
    max-height: 85vh;
    padding: var(--size-300);
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    border: 1px solid var(--border-neutral-subtle);
    h4 {
        margin-bottom: var(--size-100);
    }
}
.AlertDialogContent:focus {
    outline: none;
}

.AlertDialogTitle {
    margin: 0;
    color: var(--foreground-neutral);
}

.AlertDialogDescription {
    color: var(--foreground-neutral);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
