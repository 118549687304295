@import "variables";
@import "./radix-styles/Accordion.scss";
@import "./radix-styles/NavigationMenu.scss";
@import "./radix-styles/Popover.scss";
@import "./radix-styles/Calendar.scss";
@import "./pages/PageSharedStyles.scss";
@import "./fonts/fonts";
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    background-color: var(--background-neutral-page-back);
}
* ::-webkit-scrollbar-track {
    background: transparent;
}
* ::-webkit-scrollbar-thumb {
    background: var(--background-neutral-active);
    border-radius: 4px;
    &:hover {
        background: var(--brand-2);
    }
}
* ::-webkit-scrollbar {
    width: 4px;
}
#AppBody {
    // background-color: var(--background-neutral-page-front);
    height: 100%;
    min-height: 100vh;
    font-family: var(--font-body);
    font-weight: normal;
    --LeftSideBarWidth: 320px;
    position: relative;
}

:focus-visible {
    box-shadow: var(--focus);
    outline: none;
    border: none;
    animation: none;
}

p {
    font-size: 16px;
    color: var(--foreground-neutral);
    line-height: 160%;
    width: auto;
    margin: 0;
    padding: 0;
    font-family: var(--font-body);
    font-weight: normal;
}

h1,
h2,
h3,
h4 {
    // font-family: "MuseoModerno";
    // font-family: "Space Grotesk";
    font-family: var(--font-accent);
    font-weight: bold;
    color: var(--foreground-neutral);
}

*:not(input) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nullHeight {
    height: 0;
}

.gjs-editor {
    width: 1200px;
}
