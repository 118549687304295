:root {
    --size-50: 4px;
    --size-100: 8px;
    --size-150: 12px;
    --size-200: 16px;
    --size-300: 24px;
    --size-400: 32px;
    --size-500: 40px;
    --size-600: 48px;
    --size-700: 56px;
    --size-800: 64px;
    --size-900: 72px;
    --size-1000: 80px;
    --size-1200: 96px;
    --size-1400: 112px;
    --size-1600: 128px;

    --brand-1: #f232c8;
    --brand-2: #07feb4;
    --brand-2-200: #05daa3;
    --brand-2-300: #03b793;

    --black-100: hsl(253, 1%, 35%);
    --black-200: hsl(245, 3%, 25%);
    --black-300: hsl(248, 7%, 18%);
    --black-400: hsl(248, 17%, 8%);
    --gray-100: #ebeaec;
    --gray-200: #c8c6ca;
    --gray-300: #aca9af;
    --gray-400: #908d94;
    --red-100: #ffe1e3;
    --red-200: #ffaeb3;
    --red-500: #dd222d;
    --red-600: #a4000a;
    --red-700: #610006;
    --blue-1: #b8d0ff;
    --blue-2: #4880f0;
    --blue-2-2: #5397dd;
    --blue-2-3: #2272c3;
    --blue-2-4: #0956a5;
    --green-100: #c1ffed;
    --green-200: #57ffcd;
    --green-500: #00d696;
    --green-600: #008860;
    --green-700: #004b35;
    --yellow-100: #ffe7c4;
    --yellow-200: #ffbe5d;
    --yellow-500: #da8300;
    --yellow-600: #8b5300;
    --yellow-700: #4d2e00;
    --white: #ffffff;
    --black: var(--black-400);
    --black-alpha: rgba(33, 26, 41, 0.3);

    --background-primary: var(--brand-2);
    --background-primary-hover: var(--brand-2-200);
    --background-primary-active: var(--brand-2-300);

    --background-neutral-page-front: var(--white);
    --background-neutral-page-back: var(--gray-100);
    --background-neutral-container: var(--white);
    --background-neutral-inverted: var(--black-400);
    --background-neutral-container-inverted: var(--black-300);
    --background-neutral: var(--gray-100);
    --background-neutral-hover: var(--gray-200);
    --background-neutral-active: var(--gray-300);
    --background-neutral-disabled: var(--gray-100);

    --background-danger-subtle: var(--red-100);
    --background-danger: var(--red-500);
    --background-danger-hover: var(--red-600);
    --background-danger-active: var(--red-700);

    --foreground-primary: var(--black);

    --foreground-neutral: var(--black);
    --foreground-neutral-subtle: var(--black-200);
    --foreground-neutral-inverted: var(--gray-100);
    --foreground-neutral-placeholder: var(--black-100);
    --foreground-neutral-disabled: var(--gray-300);

    --foreground-danger-inverted: var(--white);
    --foreground-danger: var(--red-600);
    --foreground-danger-subtle: var(--red-500);

    --foreground-success: var(--green-600);
    --foreground-success-subtle: var(--green-500);

    --border-neutral: var(--gray-300);
    --border-neutral-subtle: var(--gray-200);
    --border-neutral-inverted: var(--gray-300);

    --border-danger: var(--red-500);
    --border-danger-subtle: var(--red-200);

    --border-success: var(--green-500);
    --border-success-subtle: var(--green-200);

    --focus-outer: var(--blue-1);
    --focus-inner: var(--white);
    --focus: 0px 0px 0px 2px var(--focus-inner), 0px 0px 0px 6px var(--focus-outer), 0px 0px 0px 8px var(--focus-inner);
    --shadow-level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    --shadow-level-2: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.darkmode {
    --black-alpha: hsla(248, 22%, 10%, 0.702);

    --background-neutral-page-front: var(--black);
    --background-neutral-page-back: var(--black-400);
    --background-neutral-container: var(--black-300);
    --background-neutral-inverted: var(--white);
    --background-neutral-container-inverted: var(--white);
    --background-neutral: var(--black-300);
    --background-neutral-hover: var(--black-200);
    --background-neutral-active: var(--black-100);
    --background-neutral-disabled: var(--black-200);

    --foreground-neutral: var(--white);
    --foreground-neutral-subtle: var(--gray-100);
    --foreground-neutral-inverted: var(--black);
    --foreground-neutral-placeholder: var(--gray-300);
    --foreground-neutral-disabled: var(--black-100);

    --border-neutral: var(--gray-400);
    --border-neutral-subtle: var(--black-100);
    --border-neutral-inverted: var(--gray-200);

    --focus-outer: var(--blue-2);
    --focus-inner: var(--black);
    --shadow-level-1: 0 1px 3px 0 rgba(0, 0, 0, 0.9), 0 1px 2px -1px rgba(0, 0, 0, 0.9);
    --shadow-level-2: 0px 10px 38px -10px rgba(255 255, 255, 0.35), 0px 10px 20px -15px rgba(255, 255, 255, 0.2);
}
