.PageHomePreview {
    padding: var(--size-1600) 0;
    width: 100%;
    background-color: var(--background-neutral-page-back);
    div[role="menubar"] {
        width: 100%;
        max-width: 600px;
        align-self: center;
    }

    .PageHomePreviewBodyContainer {
        max-width: 1440px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        justify-content: center;

        .PageHomePreviewBody {
            max-width: 1000px;
            width: 100%;
            position: relative;
            z-index: 0;
            box-sizing: border-box;
            flex-wrap: wrap;
            flex-direction: row;
            gap: var(--size-200);

            .EntryCard {
                border: 1px solid var(--border-neutral-subtle);
                flex: 1;
                min-height: 240px;
                flex-basis: 240px;
                position: relative;
                padding: var(--size-200) var(--size-200);
                border-radius: var(--size-100);
                color: var(--foreground-neutral);
                background-color: var(--background-neutral-container);
                .EntryCardContent {
                    height: 100%;
                }
                .Thumbnail {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    height: 100%;
                    svg {
                        width: 48px;
                        height: auto;
                        display: block;
                    }
                }
                .Title {
                    margin-top: auto;
                }
                .LockIcon {
                    position: absolute;
                    right: var(--size-100);
                    top: var(--size-100);
                    svg {
                        width: 24px;
                        height: auto;
                        display: block;
                    }
                }
                &:hover {
                    border-color: var(--brand-1);
                    cursor: pointer;
                }
            }

            .Loader {
                &:after {
                    display: none;
                }
            }
        }
    }
    .PageHomePreviewHeader {
        max-width: 1000px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: var(--size-200);
        z-index: 2;
    }
}
