.LeftSideBar {
    background-color: var(--background-neutral-container);

    // height: 100vh;
    height: auto;
    position: fixed;
    left: calc(var(--LeftSideBarWidth) - (var(--LeftSideBarWidth) * 2));
    top: 8px;
    bottom: 8px;
    width: var(--LeftSideBarWidth);
    transition: 250ms ease 0s;
    z-index: 3;
    border: 1px solid var(--border-neutral-subtle);
    box-sizing: border-box;
    overflow: hidden;
    border-radius: var(--size-100);
    box-shadow: var(--shadow-level-1);
    .organisationLogo {
        width: 100%;
        height: auto;
        display: block;
        max-width: 40px;
    }
    .LeftSideBarBody {
        padding: var(--size-200) 0;
        .paddingLR {
            padding: 0 var(--size-200);
        }
        .inputfield {
            width: 100%;
        }
        .ScrollAreaRoot {
            margin-top: var(--size-200);
            height: calc(100vh - 560px);
            // padding-right: var(--size-100);
        }
        .ScrollAreaViewport {
            width: 100%;
        }
        .ScrollAreaViewport > div {
            min-height: auto;
            flex-direction: column;
            gap: var(--size-50);
            .button {
                justify-content: flex-start;
            }
        }
    }
}
