#DashboardBody {
    max-width: 1200px;
    align-self: center;
    width: 100%;
    flex: 1;
    margin-top: var(--size-200);
}

#DashboardContainer {
    background-color: var(--background-neutral-page-back);
    width: 100%;
    flex: 1;
    transition: 250ms ease 0s;
    margin-left: auto;
    padding: 0 var(--size-200);
    &.LeftSideBar-visible {
        width: calc(100% - var(--LeftSideBarWidth));
        transition: 250ms ease 0s;
        .LeftSideBar {
            left: 8px;
            transition: 250ms ease 0s;
        }
    }
}
.DashboardContent {
    background-color: var(--background-neutral-container);
    width: 100%;
    padding: var(--size-400) var(--size-300) var(--size-500);
    border-radius: 8px;
    height: auto;
    box-shadow: var(--shadow-level-1);
    h2 {
        line-height: 1;
    }
}

#Dashboard {
    background-color: var(--background-neutral-page-back);
    width: 100%;
    flex: 1;
    padding-top: var(--size-900);
    padding-bottom: var(--size-400);
    .lottie {
        position: fixed;
        z-index: 88;
        width: 90%;
        bottom: -10vh;
        height: 100%;
        height: 100vh;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
    }

    .factsCard {
        padding: var(--size-200);
        border-radius: var(--size-100);
        border: 1px solid var(--border-neutral-subtle);
        flex: 1;
    }
}
.WidgetsDisplay {
    width: 100%;
    border-radius: var(--size-100);
    overflow: hidden;
    box-shadow: var(--shadow-level-1);
    margin-top: var(--size-200);
    .TabsContent {
        .PageContent {
            border-radius: 0;
        }
    }
}

#LibraryCards {
    gap: var(--size-200);
    flex-wrap: wrap;
    flex-grow: 272px;
}

.LibraryHeader {
    margin-bottom: var(--size-400);
    padding-bottom: var(--size-200);
    border-bottom: 1px solid var(--border-neutral-subtle);
}

#LibraryCardsHeader {
    margin-bottom: var(--size-400);
}
.levelUpScreen {
    position: fixed;
    z-index: 99;
    inset: 0;
    .lottie {
        width: 80%;
    }
    span {
        background-color: var(--brand-2);
        font-family: var(--font-accent);
        font-weight: bold;
        color: var(--black);
        font-size: var(--size-400);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%) translateY(-50%);
        // height: var(--size-400);
        line-height: 1;
        padding: var(--size-100) var(--size-200);
        border: 4px solid var(--black);
    }
}

.DialogContent.Inverted {
    background-color: var(--black);
    border: none;
    width: 70vw;
    padding: 0;
    max-width: 800px;
    overflow: hidden;
    min-height: 464px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .navigation {
        position: absolute;
        bottom: var(--size-400);
        right: var(--size-500);
        z-index: 4;
    }
    .closeButton {
        z-index: 5;
        background-color: var(--black);
        border-radius: var(--size-50);
    }

    .swiper {
        width: 100%;
        min-height: 464px;
        max-height: 486px;

        .sideLeft {
            padding: var(--size-400) var(--size-300) var(--size-1000);
            flex: 1;
            box-sizing: border-box;
            width: calc(50% - var(--size-600));
        }
        .sideRight {
            background-color: var(--black-300);
            flex: 1;
            min-width: 50%;
            pointer-events: none;
            overflow: hidden;
        }
    }
    .swiper-wrapper {
        width: 100%;
        align-items: stretch;
    }
    .swiper-slide {
        background-color: transparent;
        height: auto;
        box-sizing: border-box;
        align-self: stretch;
        align-items: flex-start;
        > .flex {
            width: auto;
            height: 100%;
        }
    }

    h1,
    h2,
    h3,
    h4,
    p {
        color: var(--gray-200);
    }
}
