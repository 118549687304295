.DialogTemplatePreview {
    overflow: hidden;
    .renderedTemplate {
        margin-top: var(--size-300);
    }

    .ScrollAreaRoot {
        max-height: 65vh;
        padding-right: var(--size-50);
        .ScrollAreaViewport {
            height: 100vh;
        }
    }

    .templateContent {
        width: 100%;
    }
    max-width: 1400px;
    width: 90%;

    @import "./../pages/ComponentRelations.scss";

    .FigmaIntegrationMessage {
        margin: 0;
        p {
            color: var(--foreground-neutral-inverted);
        }
        img {
            max-width: 40px;
            margin-top: 0;
        }
    }
}
