.Page.PageSettings {
    .SettingsAvatar {
        width: 96px;
        height: 96px;
        border-radius: 4px;
        background-color: var(--gray-300);
        margin-left: auto;
        overflow: hidden;
    }
    .inputfield,
    .uploadAvatar .button.button-outline,
    .Error {
        min-width: 300px;
        max-width: 300px;
    }
    .BrandManager,
    .uploadLogo {
        .button {
            max-width: 300px;
        }
    }
    .PageSettingsContainer {
        max-width: 1200px;
        width: 100%;
        margin-top: var(--size-300);
        .SettingsMenuSidebar {
            max-width: 230px;
            width: 100%;
            background-color: var(--background-neutral-container);
            border-radius: var(--size-100);
            box-shadow: var(--shadow-level-1);
            padding: var(--size-300) var(--size-200);
            height: auto;
            align-self: flex-start;
            min-height: 60vh;
            .SettingsItems {
                margin-top: var(--size-200);
                gap: var(--size-50);
                button {
                    justify-content: flex-start;
                }
            }
        }
        .PageBody {
            flex: 1;
            margin-top: 0;
            align-self: flex-start;
        }
    }
}
.DropdownMenuContent {
    // padding: 0;
    .react-colorful {
        margin: var(--size-100);
        width: calc(100% - var(--size-200));
        gap: var(--size-100);
        .react-colorful__saturation,
        .react-colorful__hue.react-colorful__last-control {
            border-radius: 4px;
        }
    }
}
