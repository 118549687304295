.PrivacyPolicy {
    width: 100%;
    padding: var(--size-1600) 0;
    background-color: var(--background-neutral-page-back);
    div[role="menubar"] {
        width: 100%;
    }
    .PrivacyPolicyHeader {
        max-width: 1000px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: var(--size-200);
    }
    .PrivacyPolicyBody {
        max-width: 1000px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        p {
            line-height: 160%;
        }

        h1,
        h2,
        h3,
        h4 {
            color: var(--foreground-neutral);
            line-height: 120%;
            & + h1 {
                margin-top: var(--size-100);
            }
            & + h2 {
                margin-top: var(--size-100);
            }
            & + h3 {
                margin-top: var(--size-100);
            }
            & + h4 {
                margin-top: var(--size-100);
            }
            & + p {
                margin-top: var(--size-200);
            }
        }
        p {
            line-height: 160%;
            font-size: 20px;
            color: var(--foreground-neutral);
            & + p {
                margin-top: var(--size-200);
            }
            & + h1 {
                margin-top: var(--size-600);
            }
            & + h2 {
                margin-top: var(--size-600);
            }
            & + h3 {
                margin-top: var(--size-600);
            }
            & + h4 {
                margin-top: var(--size-600);
            }
        }
        .container {
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
            gap: var(--size-400);
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            margin: var(--size-400) 0;
        }
    }
}
