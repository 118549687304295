@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: var(--foreground-neutral);
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: var(--background-neutral-container);
}
.react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
    .react-datepicker__triangle {
        top: 0;
        margin-top: -8px;
        &::after {
            top: 0;
        }
        &::before {
            top: -1px;
            border-top-color: var(--background-neutral-container);
        }
    }
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-top-color: var(--background-neutral-container);
}
.react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px;
    .react-datepicker__triangle {
        bottom: 0;
        margin-bottom: -8px;
        &::after {
            bottom: 0;
        }
        &::before {
            bottom: -1px;
            border-top-color: var(--background-neutral-container);
        }
    }
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: var(--border-neutral-subtle);
}
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}
.react-datepicker {
    font-family: var(--font-body);
    font-size: var(--size-200);
    background-color: var(--background-neutral-container);
    color: var(--foreground-neutral);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: var(--size-100);
    display: inline-block;
    position: relative;
}
.react-datepicker--time-only {
    .react-datepicker__triangle {
        left: 35px;
    }
    .react-datepicker__time-container {
        border-left: 0;
    }
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: var(--size-100);
    border-bottom-right-radius: var(--size-100);
}
.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}
.react-datepicker-popper {
    z-index: 1;
}
.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}
.react-datepicker-popper[data-placement^="right"] {
    padding-left: 8px;
    .react-datepicker__triangle {
        left: auto;
        right: 42px;
    }
}
.react-datepicker-popper[data-placement^="left"] {
    padding-right: 8px;
    .react-datepicker__triangle {
        left: 42px;
        right: auto;
    }
}
.react-datepicker__header {
    text-align: center;
    background-color: var(--background-neutral-container);
    border-bottom: 1px solid var(--border-neutral-subtle);
    border-top-left-radius: var(--size-100);
    padding: var(--size-100) 0;
    position: relative;
    &:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: var(--size-100);
    }
}
.react-datepicker__header--time {
    padding-bottom: var(--size-100);
    padding-left: var(--size-100);
    padding-right: var(--size-100);
    &:not(.react-datepicker__header--time--only) {
        border-top-left-radius: 0;
    }
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 var(--size-200);
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: var(--foreground-neutral);
    font-size: var(--size-200);
    height: 40px;
    line-height: 36px;
}
.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: var(--size-100);
    padding: 0;
    border: none;
    z-index: 1;
    height: 40px;
    width: 40px;
    text-indent: -999em;
    overflow: hidden;
    background-color: transparent;
    border-radius: var(--size-50);
    &:hover {
        background-color: var(--background-neutral-hover);
    }
    &:active {
        background-color: var(--background-neutral-active);
    }
}
.react-datepicker__navigation--previous {
    left: var(--size-100);
}
.react-datepicker__navigation--next {
    right: var(--size-100);
}
.react-datepicker__navigation--next--with-time {
    &:not(.react-datepicker__navigation--next--with-today-button) {
        right: 85px;
    }
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
}
.react-datepicker__navigation-icon {
    position: relative;
    font-size: var(--size-200);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-datepicker__navigation-icon--next {
    &::before {
        mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
        mask-size: 20px 20px;
        mask-repeat: no-repeat;
        mask-position: center;
    }
}
.react-datepicker__navigation-icon--previous {
    &::before {
        mask-image: url('data:image/svg+xml,<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.84182 3.13514C9.04327 3.32401 9.05348 3.64042 8.86462 3.84188L5.43521 7.49991L8.86462 11.1579C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0535 8.32394 12.0433 8.13508 11.8419L4.38508 7.84188C4.20477 7.64955 4.20477 7.35027 4.38508 7.15794L8.13508 3.15794C8.32394 2.95648 8.64036 2.94628 8.84182 3.13514Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>');
        mask-size: 20px 20px;
        mask-repeat: no-repeat;
        mask-position: center;
    }
}
.react-datepicker__month-container {
    //   float: left;
}
.react-datepicker__year {
    margin: var(--size-50);
    text-align: center;
    .react-datepicker__year-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}
.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}
.react-datepicker__month {
    margin: var(--size-100);
    text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid red;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
}

.react-datepicker__day-names {
    white-space: nowrap;
    margin-bottom: -8px;
}
.react-datepicker__week {
    white-space: nowrap;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--foreground-neutral);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size-400);
    height: var(--size-400);
    vertical-align: middle;
    line-height: var(--size-400);
    text-align: center;
    margin: var(--size-50);
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: var(--size-50);
    background-color: var(--brand-2-200);
    color: var(--black);
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
    position: relative;
    border-radius: 0.3rem;
    background-color: #ff6803;
    color: #fff;
}
.react-datepicker__day--holidays .holiday-overlay,
.react-datepicker__month-text--holidays .holiday-overlay,
.react-datepicker__quarter-text--holidays .holiday-overlay,
.react-datepicker__year-text--holidays .holiday-overlay {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s,
        opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
    background-color: #cf5300;
}
.react-datepicker__day--holidays:hover .holiday-overlay,
.react-datepicker__month-text--holidays:hover .holiday-overlay,
.react-datepicker__quarter-text--holidays:hover .holiday-overlay,
.react-datepicker__year-text--holidays:hover .holiday-overlay {
    visibility: visible;
    opacity: 1;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: var(--size-50);
    background-color: var(--brand-2);
    color: var(--black);
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--brand-2);
    color: var(--black);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: var(--size-50);
    background-color: var(--brand-2-200);
    color: var(--black);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--brand-2);
    color: var(--black);
}
.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background-color: var(--brand-2);
    color: var(--black);
}
.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: transparent;
    color: var(--foreground-neutral);
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: var(--foreground-neutral-disabled);
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}
.react-datepicker__children-container {
    width: 13.8rem;
    margin: 0.4rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    height: auto;
}
.react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}
.react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}
