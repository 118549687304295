/* reset */
button {
    all: unset;
}
.PopoverRoot {
    position: relative;
}

.PopoverContent {
    font-size: 16px;
    line-height: 1;
    z-index: 6;
    border-radius: var(--size-100);
    color: var(--foreground-neutral-inverted);
    user-select: none;
    animation-duration: 200ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    max-width: 500px;
    border: 1px solid var(--black-200);
    .PopoverContentInner {
        overflow: hidden;
        border-radius: var(--size-100);
        background-color: var(--background-neutral-inverted);
        box-sizing: border-box;
    }
    &:after {
        position: absolute;
        content: "";
        display: block;

        background-color: var(--foreground-neutral);

        z-index: -1;
    }
    &:before {
        position: absolute;
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background-color: var(--foreground-neutral);
    }
    * {
        color: var(--foreground-neutral-inverted);
    }
    .leftSide,
    .rightSide {
        flex: 1;
        box-sizing: border-box;
        max-width: 50%;
        overflow: hidden;
    }
    .rightSide {
        padding: var(--size-150) var(--size-200);
    }
}

// .TooltipContent[data-state='delayed-open'][data-side='top'] {
//   animation-name: slideDownAndFade;
// }
// .TooltipContent[data-state='delayed-open'][data-side='right'] {
//   animation-name: slideLeftAndFade;
// }
// .TooltipContent[data-state='delayed-open'][data-side='bottom'] {
//   animation-name: slideUpAndFade;
// }
// .TooltipContent[data-state='delayed-open'][data-side='left'] {
//   animation-name: slideRightAndFade;
// }

.PopoverContent[data-side="top"] {
    &:after {
        width: 1px;
        height: var(--size-150);
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 1px 0px 0px var(--foreground-neutral-inverted);
        bottom: -12px;
    }
    &:before {
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 1px 1px 0px var(--foreground-neutral-inverted);
    }
}
.PopoverContent[data-side="left"] {
    &:after {
        width: var(--size-150);
        height: 1px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 1px 0px 0px var(--foreground-neutral-inverted);
        right: -12px;
    }
    &:before {
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 1px 1px 0px var(--foreground-neutral-inverted);
    }
}
.PopoverContent[data-side="bottom"] {
    &:after {
        width: 1px;
        height: var(--size-150);
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 1px 0px 0px var(--foreground-neutral-inverted);
        top: -12px;
    }
    &:before {
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 1px 1px 0px var(--foreground-neutral-inverted);
    }
}
.PopoverContent[data-side="right"] {
    &:after {
        width: var(--size-150);
        height: 1px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 1px 0px 0px var(--foreground-neutral-inverted);
        left: -12px;
    }
    &:before {
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 1px 1px 0px var(--foreground-neutral-inverted);
    }
}

.PopoverAnchor {
    background-color: var(--foreground-neutral);
    width: 2px;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    height: 16px;
    top: -16px;
}

.PopoverClose {
    position: absolute;
}

// @keyframes slideUpAndFade {
//   from {
//     opacity: 0;
//     transform: translateY(2px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// @keyframes slideRightAndFade {
//   from {
//     opacity: 0;
//     transform: translateX(-2px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }

// @keyframes slideDownAndFade {
//   from {
//     opacity: 0;
//     transform: translateY(-2px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// @keyframes slideLeftAndFade {
//   from {
//     opacity: 0;
//     transform: translateX(2px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
