.AccordionRoot {
    width: 100%;
    gap: var(--size-50);
    display: flex;
    flex-direction: column;
}

.AccordionItem {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &[data-state="open"] {
        gap: var(--size-50);
    }
    > .button {
        justify-content: flex-start;
        text-align: left;
        padding: 0 var(--size-100);
        gap: var(--size-100);
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
            width: 100%;
        }
        .AccordionItemThumbnail {
            width: 24px;
            height: 24px;
            min-width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 20px;
                height: 20px;
                display: block;
            }
        }

        &[data-state="open"] {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.AccordionItem:first-child {
    margin-top: 0;
}

.AccordionItem:last-child {
}

.AccordionItem:focus-within {
    position: relative;
    z-index: 1;
}

.AccordionHeader {
    display: flex;
}

.AccordionTrigger {
    font-family: inherit;
    background-color: transparent;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AccordionTrigger:hover {
}

.AccordionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: var(--size-50);
    margin-left: var(--size-400);
    .flex > .button:not(.icon-button) {
        justify-content: flex-start;
        text-align: left;
        padding: 0 var(--size-100);
        &[aria-pressed="true"] {
            background-color: var(--brand-2);
            color: var(--black);
        }
        gap: var(--size-150);
        img {
            width: 24px;
            height: 24px;
            border-radius: 2px;
            border: 1px solid var(--border-neutral-subtle);
        }
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
            width: 100%;
        }
    }
}
.AccordionContent[data-state="open"] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
// .AccordionContent[data-state="closed"] {
//     animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
// }

.AccordionChevron {
    color: var(--violet-10);
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state="open"] > .AccordionChevron {
    transform: rotate(180deg);
}

@keyframes slideDown {
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
}

@keyframes slideUp {
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
}
