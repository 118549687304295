#LoginContainer {
    // background-image: url("./assets/LoginBG.svg");
    background-color: var(--background-neutral-page-back);
    background-repeat: repeat-y;
    // background-size: 306px auto;
    background-image: url("./assets/PlaceholderImage.svg");
    background-size: auto 100%;
    background-position: right center;
    width: 100%;
    // padding-top: var(--size-800);
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    .LoginLogo {
        max-width: 174px;
        width: 100%;
        height: auto;
        display: block;
    }
    .flex {
        & + button {
            margin-top: var(--size-200);
        }
    }
    .TabsRoot {
        width: 360px;
        border: 2px solid var(--border-neutral);
        border-radius: 8px;
        z-index: 2;
        overflow: hidden;
    }
    .TabsContent {
        padding: var(--size-400);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .TabsTrigger:first-child {
        border-top-left-radius: 4px;
    }
    .TabsTrigger:last-child {
        border-top-right-radius: 4px;
    }
    fieldset {
        display: flex;
        flex-direction: column;
        gap: var(--size-300);
        margin-bottom: var(--size-300);
        width: 100%;
        flex: 1;
        margin-top: var(--size-200);
        input,
        .inputfield {
            width: 100%;
        }
    }
    p.Error {
        margin-top: var(--size-200);
    }
    // #signupStep2 {
    //     margin-top: var(--size-200);
    //     margin-bottom: var(--size-400);
    //     .inputfield {
    //         max-width: 30%;
    //         flex: 1;
    //         input {
    //             min-width: 0;
    //         }
    //     }
    // }
    .LoginInfo {
        height: 100vh;
        position: fixed;
        left: 0;
        padding-left: var(--size-200);
        top: 0;
        bottom: 0;
        width: 50%;
        background-color: var(--black-400);
        z-index: 0;
        // ul {
        //     display: flex;
        //     gap: var(--size-150);
        //     flex-direction: column;
        //     li {
        //         color: var(--gray-100);
        //     }
        // }

        p,
        h3 {
            color: var(--gray-100);
        }
    }
}
