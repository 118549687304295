.Page {
    background-color: var(--background-neutral-page-back);
    width: 100%;
    flex: 1;
    padding-top: var(--size-900);
    padding-bottom: var(--size-400);
    .PageHeader {
    }
}
.PageBody {
    max-width: 1200px;
    align-self: center;
    width: 100%;
    flex: 1;
    margin-top: var(--size-200);
}
.PageContent {
    background-color: var(--background-neutral-container);
    width: 100%;
    padding: var(--size-400) var(--size-300) var(--size-500);
    border-radius: 8px;
    box-shadow: var(--shadow-level-1);
    overflow: hidden;
    position: relative;
    &.paddingReduced {
        padding-bottom: 0;
    }
    h2 {
        line-height: 1;
    }
}
