.ComponentGrid {
    gap: var(--size-200);
    flex-wrap: wrap;
    flex-grow: 276px;
    display: flex;
    flex-direction: row;
}
.QuickActions {
    // margin-bottom: var(--size-200);
    margin-top: var(--size-200);
    button {
        min-width: 200px;
    }
    button:first-child {
    }
    button:nth-child(2) {
    }
}
