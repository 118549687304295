.ComponentCardBoard {
    .description {
        max-width: 700px;
    }
    .Figma {
        margin-top: var(--size-400);
        margin-bottom: var(--size-400);
        .Thumbnail {
            max-width: 272px;
            max-height: 272px;
            flex: 1;
            overflow: hidden;
            border-radius: 8px;
        }
        iframe {
            flex: 2;
            overflow: hidden;
            border-radius: 8px;
            height: 100%;
            min-height: 400px;
            width: 100%;
        }
    }
    .FigmaIntegrationMessage {
        height: 100%;
        max-height: 100%;
        border-radius: 8px;
    }
    .GithubBranchCommit {
        height: 100%;
        margin: 0;
    }
}

.IconDisplay {
    width: 100%;
    flex-wrap: wrap;
    padding: var(--size-500) var(--size-200);
    border: 1px dashed var(--border-neutral-subtle);
    border-radius: var(--size-150);
    background-color: var(--background-neutral-page-back);
    position: relative;
    overflow: hidden;
    margin-top: var(--size-300);
    p {
        position: absolute;
        left: 16px;
        top: 8px;
    }
    &Container {
        @media only screen and (max-width: 490px) {
            button {
                width: 100%;
            }
        }
    }
    .IconBox {
        min-width: 136px;
        flex: 1;
        height: 136px;
        border: 1px solid var(--border-neutral-subtle);
        border-radius: var(--size-100);
        align-items: center;
        justify-content: center;
        display: flex;
        position: relative;
        background-color: var(--background-neutral-container);
        overflow: hidden;
        padding: var(--size-300) 0;
        // color: var(--foreground-neutral);
        button {
            position: absolute;
            bottom: var(--size-50);
            right: var(--size-50);
        }
        span {
            position: absolute;
            top: 8px;
            left: 8px;
            font-size: 10px;
            color: var(--foreground-neutral);
        }

        // svg {
        //     * {
        //         fill: currentColor;
        //     }
        // }
    }
    &.onlyIllustrations {
        flex-direction: column !important;
        .IconBox {
            height: auto;
            width: 100%;
            max-width: 100%;
            flex: 1;
            padding: var(--size-500) 0;
        }
    }
}
