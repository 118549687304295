.cardSelectionDialog[role="dialog"] {
    max-width: 900px;
    width: 100%;
    padding-bottom: var(--size-700);
    padding-left: 0;
    padding-right: 0;
    .ScrollAreaRoot {
        height: auto;
        padding: 0 var(--size-600);
        .ScrollAreaViewport {
            height: 400px;
        }
    }
    .inputfield {
        margin-bottom: var(--size-400);
    }
    .cardSelectionDialogHeader {
        padding-right: var(--size-100);
        padding: 0 var(--size-600);
    }
    .iframeCard {
        min-width: 308px;
        padding: var(--size-150) var(--size-150);
        h4 {
            margin-bottom: var(--size-150);
        }
    }
    .TabsList {
        margin-bottom: var(--size-400);
    }
}
.cardSelector {
    flex-wrap: wrap;
    flex-grow: 124px;
    cursor: pointer;
    .cardOption {
        overflow: hidden;
        border-radius: 8px;
        width: 146px;
        max-width: 146px;
        box-sizing: border-box;
        border: 1px solid var(--border-neutral-subtle);
        padding-bottom: var(--size-150);
        position: relative;
        &:hover {
            border: 1px solid var(--brand-1);
        }
        .cardOptionThumbnail {
            height: 146px;
            overflow: hidden;
            width: 100%;
            pointer-events: none;
            border-bottom: 1px solid var(--border-neutral);
            box-sizing: border-box;
            position: relative;
            img {
                width: auto;
                height: 100%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        p {
            margin-top: var(--size-100);
            padding: 0 var(--size-100);
        }
        .Tag {
            position: absolute;
            top: var(--size-100);
            right: var(--size-100);
        }
    }
}
