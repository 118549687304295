.Community {
    width: 100%;
    padding: var(--size-1600) 0;
    background-color: var(--background-neutral-page-back);
    div[role="menubar"] {
        width: 100%;
    }

    .flareGreen {
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: -100px;
            width: 300px;
            height: 600px;
            filter: blur(124px);
            background-color: var(--brand-2);
            opacity: 0.6;
            content: "";
            display: block;
            border-radius: 20%;
            transform: rotate(-45deg);
            z-index: -1;
        }
    }
    .flareMagenta {
        position: relative;
        &:before {
            position: absolute;
            right: 200px;
            bottom: -300px;
            width: 200px;
            height: 900px;
            filter: blur(124px);
            background-color: var(--brand-1);
            opacity: 0.6;
            content: "";
            display: block;
            border-radius: 20%;
            transform: rotate(45deg);
            z-index: -1;
        }
    }
    .CommunityHeader {
        max-width: 1000px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: var(--size-200);
        z-index: 4;
    }
    .CommunityBody {
        max-width: 1200px;
        padding: 0 var(--size-200);
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 0;
        .featuredTemplatesGrid {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            gap: var(--size-200);
            position: relative;
            z-index: 0;
            margin-bottom: var(--size-1000);
            margin-top: var(--size-200);
        }
        .templatesGrid {
            margin-top: var(--size-200);

            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            gap: var(--size-200);
            position: relative;
            z-index: 0;
        }
    }
    .CommunityHero {
        text-align: center;
        margin-bottom: var(--size-1200);
        margin-top: var(--size-300);
        gap: var(--size-150);
        h2,
        h3,
        h4 {
            max-width: 44ch;
            align-self: center;
        }
    }
    .templateSearch {
        width: 100%;
        text-align: left;
        align-items: center;
        flex: 1;
        margin-top: var(--size-300);
        position: relative;
        max-width: 400px;
        align-self: center;
        .inputfield {
            width: 100%;
            max-width: 400px;
        }
        .customDropdown {
            z-index: 9;
            min-width: 220px;
            height: 300px;
            overflow: hidden;
            width: 100%;
            position: absolute;
            top: 52px;
            left: 0;
            background-color: var(--background-neutral-container);
            border-radius: 4px;
            padding: var(--size-100);
            box-shadow: var(--shadow-level-2);
            animation-duration: 400ms;
            animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            will-change: transform, opacity;
            border: 1px solid var(--border-neutral-subtle);
            gap: var(--size-50);
            .ScrollAreaRoot {
                height: 100%;
                padding-right: var(--size-50);
            }
            .ScrollAreaViewport > div {
                min-height: 0;
                flex-direction: column;
            }
        }
    }
}
.no-scroll {
    overflow: hidden;
}
