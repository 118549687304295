.Cookies {
    position: fixed;
    bottom: var(--size-100);
    left: var(--size-100);
    width: 100%;
    max-width: 400px;
    background-color: var(--black);
    padding: var(--size-200) var(--size-300);
    border-radius: 8px;
    text-align: left;
    border: 1px solid var(--gray-200);
    z-index: 1000;
    p {
        color: var(--gray-100);
    }
    .button-danger {
        min-width: 128px;
    }
    .disabled {
        .button-danger {
            background-color: var(--brand-2);
            color: var(--black);
            &:hover {
                background-color: var(--background-primary-hover);
            }
        }
    }

    .CheckboxRoot {
        background-color: var(--black-300);
        border: 1px solid var(--black-100);
        &:hover {
            background-color: var(--black-100);
        }
        &[data-state="checked"] {
            background-color: var(--background-primary);
        }
        .CheckboxIndicator {
            color: var(--black);
        }
        .Label {
            color: var(--gray-100);
        }
    }
}
