.ComponentCard {
    padding-bottom: var(--size-200);
    border: 1px solid var(--border-neutral-subtle);
    border-radius: 8px;
    width: auto;
    flex: 1;
    max-width: 276px;
    min-width: 272px;
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &:hover {
        border: 1px solid var(--brand-1);
        .actionButtons {
            opacity: 1;
        }
    }
    .avatarContainer {
        margin-left: -2px;
    }
    .CardFooter {
        justify-content: flex;
        width: 100%;
        padding: 0 var(--size-200);
        margin-top: auto;
    }
    .Thumbnail {
        width: 100%;
        height: 270px;
        overflow: hidden;
        position: relative;
        pointer-events: none;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: auto;
            transform: translateX(-50%) translateY(-50%);
        }
        .Tag {
            position: absolute;
            bottom: 8px;
            right: 8px;
            z-index: 1;
        }
    }
    p {
        width: 100%;
        line-height: 140%;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
        & + p {
            margin-top: var(--size-100);
        }
        &.description {
            text-wrap: wrap;
            line-height: 140%;
            color: var(--foreground-neutral-subtle);
            margin-bottom: var(--size-200);
        }
    }
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    .actionButtons {
        position: absolute;
        top: var(--size-100);
        left: var(--size-100);
        z-index: 1;
        opacity: 0;
        transition: 250ms ease 0s;
    }
    .actionButtonsRight {
        position: absolute;
        top: var(--size-100);
        right: var(--size-100);
        z-index: 1;
    }
}
