h1,
h2,
h3,
h4 {
    max-width: 32ch;
    & + h1 {
        margin-top: var(--size-100);
    }
    & + h2 {
        margin-top: var(--size-100);
    }
    & + h3 {
        margin-top: var(--size-100);
    }
    & + p {
        margin-top: var(--size-200);
    }
    & + .CodeHighlight {
        margin-top: var(--size-300);
    }
    & + .iframeComponent {
        margin-top: var(--size-300);
    }
    & + .Table {
        margin-top: var(--size-200);
    }
    & + .card {
        margin-top: var(--size-200);
    }
    & + .button {
        margin-top: var(--size-200);
    }
}
p {
    line-height: 180%;
    // font-size: 20px;
    color: var(--foreground-neutral-subtle);
    max-width: 86ch;
    & + p {
        margin-top: var(--size-200);
    }
    & + h1 {
        margin-top: var(--size-600);
    }
    & + h2 {
        margin-top: var(--size-600);
    }
    & + h3 {
        margin-top: var(--size-600);
    }
    & + .CodeHighlight {
        margin-top: var(--size-600);
    }
    & + .iframeComponent {
        margin-top: var(--size-600);
    }
    & + .Table {
        margin-top: var(--size-200);
    }
    & + .card {
        margin-top: var(--size-200);
    }
    & + .button {
        margin-top: var(--size-200);
    }
}
.CodeHighlight {
    & + .iframeComponent {
        margin-top: var(--size-600);
    }
}
.container {
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    gap: var(--size-100);
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: var(--size-400) 0;
    flex-wrap: wrap;
}
.card {
    background-color: var(--background-neutral-container);
    max-width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 276px;
    max-width: 276px;
    border: 1px solid var(--border-neutral-subtle);
    padding-bottom: var(--size-200);
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    transition: 250ms ease 0s;
    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + .container,
    & + .CodeHighlight,
    & + .iframeComponent,
    & + .Table {
        margin-top: var(--size-400);
    }
    .image {
        transition: 250ms ease 0s;
    }
    .cardTexts {
        align-self: flex-start;
    }
    &.cardExpanded {
        width: 100%;
        transition: 250ms ease 0s;
        max-width: 100%;
        min-width: 100%;
        align-items: flex-start;
        .cardImageText {
            flex-direction: row;
            transition: 250ms ease 0s;
            width: 100%;
        }

        .image {
            width: 96px;
            height: 96px;
            transition: 250ms ease 0s;
            border-radius: var(--size-50);
            align-self: self-start;
            margin-left: var(--size-200);
            margin-top: var(--size-200);
        }
        .showMore {
            width: 100%;
        }
    }
    .showMore {
        width: 0;
        padding: var(--size-200);
        iframe {
            width: 100%;
            transition: 250ms ease 0s;
        }
    }
    img {
        height: 276px;
        width: max-content;
        align-self: center;
        display: flex;
    }
    .icon-button {
        position: absolute;
        top: var(--size-100);
        right: var(--size-100);
    }
    p:nth-of-type(1) {
        margin: auto var(--size-200);
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        padding-top: var(--size-150);
        & + p {
            padding-top: var(--size-100);
            padding-bottom: var(--size-200);
            margin: auto var(--size-200);
            font-size: 16px;
            line-height: 24px;
        }
    }
    .button {
        margin: auto var(--size-200);
        margin-top: var(--size-200);
    }
}
.iframeComponent {
    min-height: 540px;
    border-radius: 8px;
    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + .container,
    & + .CodeHighlight,
    & + .iframeComponent,
    & + .Table,
    & + .button {
        margin-top: var(--size-400);
    }
}
.Table {
    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + .container,
    & + .CodeHighlight,
    & + .iframeComponent,
    & + .button {
        margin-top: var(--size-400);
    }
}
.button {
    & + p,
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + .container,
    & + .CodeHighlight,
    & + .iframeComponent {
        margin-top: var(--size-400);
    }
}
