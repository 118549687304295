@font-face {
    font-family: "Space Grotesk";
    src: url("space/SpaceGrotesk-Regular.eot");
    src:
        url("space/SpaceGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
        url("space/SpaceGrotesk-Regular.woff2") format("woff2"),
        url("space/SpaceGrotesk-Regular.woff") format("woff"),
        url("space/SpaceGrotesk-Regular.ttf") format("truetype"),
        url("space/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("space/SpaceGrotesk-Bold.eot");
    src:
        url("space/SpaceGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
        url("space/SpaceGrotesk-Bold.woff2") format("woff2"),
        url("space/SpaceGrotesk-Bold.woff") format("woff"),
        url("space/SpaceGrotesk-Bold.ttf") format("truetype"),
        url("space/SpaceGrotesk-Bold.svg#SpaceGrotesk-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("opensans/OpenSans.eot");
    src:
        url("opensans/OpenSans.eot?#iefix") format("embedded-opentype"),
        url("opensans/OpenSans.woff2") format("woff2"),
        url("opensans/OpenSans.woff") format("woff"),
        url("opensans/OpenSans.ttf") format("truetype"),
        url("opensans/OpenSans.svg#OpenSans") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("opensans/OpenSans-Bold.eot");
    src:
        url("opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("opensans/OpenSans-Bold.woff2") format("woff2"),
        url("opensans/OpenSans-Bold.woff") format("woff"),
        url("opensans/OpenSans-Bold.ttf") format("truetype"),
        url("opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Display";
    src:
        url("noto/NotoSansDisplay-Light.woff2") format("woff2"),
        url("noto/NotoSansDisplay-Light.woff") format("woff"),
        url("noto/NotoSansDisplay-Light.ttf") format("truetype"),
        url("noto/NotoSansDisplay-Light.svg#noto/NotoSansDisplay-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Display";
    src:
        url("noto/NotoSansDisplay-SemiBold.woff2") format("woff2"),
        url("noto/NotoSansDisplay-SemiBold.woff") format("woff"),
        url("noto/NotoSansDisplay-SemiBold.ttf") format("truetype"),
        url("noto/NotoSansDisplay-SemiBold.svg#noto/NotoSansDisplay-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Display";
    src:
        url("noto/NotoSansDisplay-Regular.woff2") format("woff2"),
        url("noto/NotoSansDisplay-Regular.woff") format("woff"),
        url("noto/NotoSansDisplay-Regular.ttf") format("truetype"),
        url("noto/NotoSansDisplay-Regular.svg#noto/NotoSansDisplay-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DarkerGrotesque";
    src: url("darker/DarkerGrotesque-Regular.eot");
    src:
        url("darker/DarkerGrotesque-Regular.eot?#iefix") format("embedded-opentype"),
        url("darker/DarkerGrotesque-Regular.woff2") format("woff2"),
        url("darker/DarkerGrotesque-Regular.woff") format("woff"),
        url("darker/DarkerGrotesque-Regular.ttf") format("truetype"),
        url("darker/DarkerGrotesque-Regular.svg#DarkerGrotesque-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DarkerGrotesque-Bold";
    src: url("darker/DarkerGrotesque-Bold.eot");
    src:
        url("darker/DarkerGrotesque-Bold.eot?#iefix") format("embedded-opentype"),
        url("darker/DarkerGrotesque-Bold.woff2") format("woff2"),
        url("darker/DarkerGrotesque-Bold.woff") format("woff"),
        url("darker/DarkerGrotesque-Bold.ttf") format("truetype"),
        url("darker/DarkerGrotesque-Bold.svg#DarkerGrotesque-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DarkerGrotesque-Light";
    src: url("darker/DarkerGrotesque-Light.eot");
    src:
        url("darker/DarkerGrotesque-Light.eot?#iefix") format("embedded-opentype"),
        url("darker/DarkerGrotesque-Light.woff2") format("woff2"),
        url("darker/DarkerGrotesque-Light.woff") format("woff"),
        url("darker/DarkerGrotesque-Light.ttf") format("truetype"),
        url("darker/DarkerGrotesque-Light.svg#DarkerGrotesque-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
