.WebPage {
    width: 100%;
    flex: 1;
    overflow: hidden;
    padding-top: var(--size-900);
    background-color: var(--background-neutral-page-back);

    // padding-bottom: var(--size-400);
    .cta {
        background-image: url("../assets/PlaceholderImage5.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border: 2px solid var(--brand-1);
        padding: var(--size-400);
        border-radius: var(--size-100);
        h1,
        h2,
        h3,
        h4,
        p {
            color: var(--black);
        }
        .button {
            margin-top: var(--size-400);
        }
    }
    .WebPageBody {
        max-width: 1200px;
        align-self: center;
        width: 100%;
        flex: 1;
        margin-top: var(--size-200);
        padding-bottom: var(--size-400);
    }
    .section {
        width: 100%;
        align-self: center;
        min-height: 400px;
        align-items: center;
        justify-content: center;
        padding: var(--size-800) var(--size-200);
        box-sizing: border-box;
        position: relative;
        @media screen and (max-width: 835px) {
            padding: var(--size-500) var(--size-200);
        }

        &.Hero {
            min-height: 700px;
            padding-top: 64px;
            position: relative;
        }
        .row {
            max-width: 1200px;
            width: 100%;
            align-self: center;
            gap: var(--size-800);
            position: relative;
            @media screen and (max-width: 835px) {
                gap: var(--size-600);
            }
            @media screen and (max-width: 600px) {
                gap: var(--size-400);
            }
            @media screen and (max-width: 992px) {
            }
            @media screen and (max-width: 835px) {
                flex-direction: column;
            }
            @media screen and (max-width: 600px) {
            }
            .column {
                flex: 1;
                width: 100%;
                position: relative;
                &.imageLarge {
                    img {
                        width: 120%;
                        margin-left: -10%;
                        margin-right: -10%;
                        // @media screen and (max-width: 835px) {
                        //     width: 110%;
                        //     margin-left: -10%;
                        //     margin-right: -10%;
                        // }
                    }
                }
                &.flex {
                    &-1 {
                        flex: 1;
                    }
                    &-2 {
                        flex: 2;
                    }
                    &-3 {
                        flex: 3;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
    }
    .tcenter {
        text-align: center;
    }
    h1,
    h2,
    h3,
    h4 {
        max-width: 35ch;
        line-height: 110%;
    }
    p {
        max-width: 735px;
        & + a.button {
            margin-top: var(--size-400);
            display: flex;
        }
        & + .ComponentPreview,
        & + .ComponentPreviewCode,
        & + .TabsRoot {
            margin-top: var(--size-400);
        }
    }
    h2 {
        & + p {
            margin-top: var(--size-100);
        }

        & + .ComponentPreview,
        & + .ComponentPreviewCode,
        & + .TabsRoot {
            margin-top: var(--size-400);
        }
    }
    h3 {
        & + p {
            margin-top: var(--size-100);
        }

        & + .ComponentPreview,
        & + .ComponentPreviewCode,
        & + .TabsRoot {
            margin-top: var(--size-400);
        }
    }

    .ComponentPreview,
    .ComponentPreviewCode,
    .TabsRoot {
        & + h2,
        & + h3,
        & + h4,
        & + p {
            margin-top: var(--size-400);
        }
    }

    footer {
        background-color: var(--brand-2);
        height: 64px;
        width: 100%;
    }
    .minHeight {
        min-height: 640px;
    }
    .YoutubeVideo-Container {
        max-width: 735px;
        margin-top: var(--size-400);
        align-self: center;
    }

    @media screen and (max-width: 835px) {
        flex-direction: column;
        .order1 {
            order: 1;
        }
        .order2 {
            order: 2;
        }
        .minHeight {
            min-height: 0;
        }
    }
    @media screen and (max-width: 600px) {
        .imageMobileLarge {
            img {
                width: 160% !important;
                margin-left: -30%;
                margin-right: -30%;
            }
        }
        h1 {
            font-size: var(--size-500);
        }

        h2 {
            font-size: var(--size-400);
        }

        h3 {
            font-size: var(--size-300);
            line-height: 140%;
        }

        h4 {
            font-size: 20px;
            line-height: 140%;
        }
        h1,
        h2,
        h3,
        h4,
        p {
            text-align: center;
            max-width: 100%;
            width: 100%;
        }
        .button {
            align-self: center;
        }
    }
}
