.templateItem {
    gap: var(--size-100);
    padding: var(--size-50) var(--size-100);
    border-radius: var(--size-50);
    .image {
        width: var(--size-500);
        height: var(--size-500);
        overflow: hidden;
        border-radius: var(--size-50);
        img {
            height: auto;
            width: 100%;
        }
    }
    &:hover {
        background-color: var(--brand-2);
        .templateInfo {
            p {
                color: var(--black);
                &:last-child {
                    color: var(--black);
                }
            }
        }
    }
    .templateInfo {
        overflow: hidden;
        p {
            width: 100%;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
            color: var(--foreground-neutral);
            &:last-child {
                color: var(--foreground-neutral-subtle);
            }
        }
    }
}
